@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import './00_Variables/10_Overrides/Spacing';

.store-locator{
    position: relative;
}
.locator-map{
    width: calc(100% + var(--container-padding-x, 60px) * 2);
    height: 80vh;
    margin-left: calc(var(--container-padding-x, 60px) * -1);
    @include media-breakpoint-up(md){
        width: calc(100% + var(--container-padding-x, 60px));
        height: 80vh;
    }
}
.locator-content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    @include media-breakpoint-down(md){
        order: -1;
    }
}
.locator-title{
    margin-bottom: #{$spacer * 2};
    @include media-breakpoint-down(md){
        @include make-col(5,6);
    }
}
.locator-text{
    position: relative;
    padding-top: #{$spacer * 4};
    padding-bottom: #{$spacer * 4};
    margin-bottom: #{$spacer * 8};
    max-width: 600px;
    &::before{
        content: "";
        position: absolute;
        top:0;
        left: calc((100% + var(--bs-gutter-x)) / -4);
        width: calc((100% / 4 * 6) + var(--bs-gutter-x) * .5);
        height: 100%;
        z-index: -1;

        background-color: var(--color-white);;
    }
    @include media-breakpoint-down(md){
        @include make-col(5,6);
        @include make-col-offset(1,6);
        padding: 30px 40px;
        margin-bottom: #{$spacer * 3};
        &::before{
            width: 100%;
            height: 100%;
            left: 0;
        }
    }
}
#info-window{
    position: absolute;
    left: calc(var(--container-padding-x, 60px) - 30px);
    bottom: 30px;
    z-index: 2;
    width: 366px;
    max-width: calc(100% - 80px);
    padding: 30px 25px;
    background: var(--color-white);
}
#close-info-window{
    position: absolute;
    top: 15px;
    right: 15px;
    border-color: none;
    background: none;
    background-color: none;
    &::before{
        content: none;
    }
}
.headline{
    font-weight: var(--body-medium);
    margin-bottom: #{$spacer * 1};
}
.address{
    margin-bottom: #{$spacer * 1};
}
.locator-contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
